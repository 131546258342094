import { defineStore } from 'pinia'
import { filter, asHTML } from '@prismicio/client';

import { usePrismicClient } from '~/composables/usePrismicClient'
import type { FooterType } from '@/types'

export const useFooterStore = defineStore('footer', () => {
  const { client } = usePrismicClient()
  const { setLoading } = useUIStore()

  const footer = ref<FooterType>()

  const parseFooter = (doc: Record<string, any>): FooterType | undefined => {
    try {
      return {
        x_url: doc.x_url || '',
        facebook_url: doc.facebook_url || '',
        instagram_url: doc.instagram_url || '',
        whatsapp_url: doc.whatsapp_url || '',
        contacts: asHTML(doc.contacts) || '',
        working_hours: asHTML(doc.working_hours) || '',
        content: asHTML(doc.content) || '',
        last: asHTML(doc.last) || '',
      }
    } catch (error) {
      console.error('Error parsing footer data from Prismic:', error)
    }
  }

  const fetchFooter = async (): Promise<FooterType | undefined> => {
    try {
      setLoading(true)
      const response = await client.get({
        filters: [
          filter.at('document.type', 'footer'),
        ],
      })

      const parsedResponse = parseFooter(response.results.map(({ data, id }) => ({ ...data, id }))[0])

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching footer data from Prismic:', error)
      return Promise.resolve(undefined)
    } finally {
      setLoading(false)
    }
  }

  const loadFooter = async (): Promise<boolean> => {
    try {
      footer.value = await fetchFooter()
      return Promise.resolve(true)
    } catch (error) {
      console.error('Error loading footer data from Prismic:', error)
      return Promise.resolve(false)
    }
  }

  return {
    footer,

    loadFooter,
    fetchFooter,
  }
})

import { onMounted, ref } from 'vue'

export const useHydration = () => {
  const isHydrated = ref(false)

  onMounted(() => {
    isHydrated.value = true
  })

  return { isHydrated }
};
<template>
  <div v-if="showTrustpilot" ref="trustbox" data-locale="it-IT" :data-template-id="trustpilotTemplateId"
    :data-businessunit-id="trustpilotBusinessId" data-style-height="28px" data-style-width="100%" data-theme="dark">
    <NuxtLink :to="trustpilotUrl" target="_blank" title="Trustpilot url" external>
      Trustpilot
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const {
  trustpilotBusinessId,
  trustpilotUrl,
  trustpilotTemplateId,
} = useEnvVariables()

const trustbox = ref(null)

const showTrustpilot = computed(() => trustpilotBusinessId && trustpilotUrl && trustpilotTemplateId)

const loadTrustpilot = () => {
  if (!showTrustpilot.value) {
    return
  }

  if (window === undefined || !('Trustpilot' in window)) {
    return
  }

  if (trustbox.value === null) {
    return
  }

  nextTick(() => window.Trustpilot.loadFromElement(trustbox.value))
}

onMounted(() => loadTrustpilot())
</script>

import { defineStore } from 'pinia'
import { filter, asHTML } from '@prismicio/client';

import { usePrismicClient } from '~/composables/usePrismicClient'
import type { BannerType, DisclaimerType } from '@/types'

export const useBannerStore = defineStore('banner', () => {
  const { client } = usePrismicClient()
  const { setLoading } = useUIStore()

  const banner = ref<DisclaimerType>()
  const isBannerVisible = ref(false)

  const parseBanner = (doc: Record<string, any>): DisclaimerType | undefined => {
    try {
      return {
        content: asHTML(doc.content) || '',
      }
    } catch (error) {
      console.error('Error parsing banner data from Prismic:', error)
    }
  }

  const fetchBanner = async (): Promise<BannerType | undefined> => {
    try {
      setLoading(true)
      const response = await client.get({
        filters: [
          filter.at('document.type', 'banner'),
        ],
      })

      if (response.results.length === 0) {
        return Promise.resolve(undefined)
      }

      const parsedResponse = parseBanner(response.results.map(({ data, id }) => ({ ...data, id }))[0])

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching banner data from Prismic:', error)
      return Promise.resolve(undefined)
    } finally {
      setLoading(false)
    }
  }

  const loadBanner = async (): Promise<boolean> => {
    try {
      banner.value = await fetchBanner()

      if (banner.value?.content) {
        isBannerVisible.value = true
      }
      return Promise.resolve(true)
    } catch (error) {
      console.error('Error loading banner data from Prismic:', error)
      return Promise.resolve(false)
    }
  }

  const closeBanner = () => {
    isBannerVisible.value = false
  }

  return {
    banner,
    isBannerVisible,

    loadBanner,
    fetchBanner,
    closeBanner,
  }
})